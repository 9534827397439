import { collapseMenu } from "./components/collapse-menu";
import { externalLinks } from "./components/external-links";
import { generalFunctions } from "./components/general-functions";
import { svgSprite } from "./components/svg-sprite";

generalFunctions();
svgSprite();

document.addEventListener('DOMContentLoaded', () => {
  /* eslint-disable no-new, no-restricted-syntax */
  collapseMenu();
  externalLinks();
});
